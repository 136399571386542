@use '../../../styles/_global-variables.scss' as global;

.nodisponible {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 82px auto auto;
    justify-items: center;
    background-image: radial-gradient(49.99% 47.21% at 49.99% 52.79%, #414141 0%, #111 100%);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 70% 100%;
    padding: 32px 16px 100px;
}

.logodirecte {
    height: 56px;
}

.titol,
.sotstitol {
    font-weight: 300;
    font-size: var(--headline_l);
    text-align: center;
    line-height: 1.3;
    padding-bottom: 0.3em;
    max-width: 544px;
}

.sotstitol {
    font-size: var(--headline_xs);
}

@media (min-width: 768px) {
    .nodisponible {
        padding: 110px 120px;
        background-size: 50% 100%;
    }
}
